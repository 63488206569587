import { DATE_FORMAT_SHORT_REVERSE } from 'common/data/constants';
import {
  CONSULAR_FEE_SERVICE_VALUE,
  DELIVERY_SERVICE_VALUE,
  GENERAL_SERVICE_VALUE,
  INVITATION_SERVICE_VALUE,
  VISA_APPLICATON_CENTER_FEE_SERVICE_VALUE,
  VISA_SUPPORT_FEE_SERVICE_VALUE,
} from 'common/data/services';
import { PREVIOUS_VISA_STATE } from 'components/Order/Tabs/ApplicantMainInfo';
import { optionalField } from 'forms/shortcuts';
import { formatCurrency } from './formatCurrency';
import { formatDate } from './formatDate';
import { isUserEmployee, isUserOperator } from './jwt_helper';

export const capitalizeFirstLetter = string =>
  string.charAt(0).toUpperCase() + string.slice(1);

export const convertPriceToAPI = price =>
  Math.round(Number(price?.toString().replace(/€|,|\s/g, '')) * 100);

export const getLabelFromAPI = (value, array) =>
  array.find(item => item.value === value)?.label;

export const convertDateToAPI = value =>
  value ? formatDate(value, DATE_FORMAT_SHORT_REVERSE) : null;

export const convertPassportFromApi = passport => ({
  id: passport?.id,
  number: passport?.number,
  nationality: passport?.country?.id,
  expirationDate: new Date(passport.end_date),
  startDate: new Date(passport.issue_date),
  visa_order_require_residency: passport.country.visa_order_require_residency,
});

export const convertAdditionalField = field => (field ? field : undefined);

export const convertIndividualToApi = individual => ({
  email: individual.email,
  password: individual.password,
  first_name: individual.first_name,
  last_name: individual.last_name,
  additional_name: individual.additional_name,
  phone: individual.phone,
  address: {
    address1: individual.addressLineFirst,
    postal_code: convertAdditionalField(individual.postalCode),
    city: individual.city,
    state: convertAdditionalField(individual.state),
    country: individual.country,
  },
});

export const convertServiceToApi = service => {
  const convertDefaultField = {
    description: service.description,
    name: service.name,
    price: service.product_net_price_euro,
    status: service.status,
    tax_enabled: service.taxEnabled,
    is_administrative: service.isAdministrative,
    tariffs:
      service?.tariffs?.map(tariff => ({
        id: tariff.id,
        price: tariff.price === '€0.00' ? 0 : convertPriceToAPI(tariff.price),
        auto_select: tariff.autoSelect,
        tariff: tariff?.tariff.value,
        tags: tariff?.tags,
      })) ?? [],
    order_type: service.orderType,
    product_type: service.serviceType,
    processing: service.processing ?? 0,
    visa_urgency: service.visaUrgency,
  };

  switch (service.serviceType) {
    case GENERAL_SERVICE_VALUE:
      return {
        ...convertDefaultField,
        // auto_select: service.autoSelect,
        country: service.country,
        customer_nationality: service.nationality,
        except_nationalities: service.exceptNationalities?.map(
          country => country.id,
        ),
      };
    case DELIVERY_SERVICE_VALUE:
      return convertDefaultField;

    case CONSULAR_FEE_SERVICE_VALUE:
    case VISA_APPLICATON_CENTER_FEE_SERVICE_VALUE:
    case VISA_SUPPORT_FEE_SERVICE_VALUE:
      return {
        ...convertDefaultField,
        country: service.country,
        customer_nationality_region: service.nationalityRegion,
        visa_type: service.visaType,
        visa_urgency: service.visaUrgency,
        visa_duration: service.visaDuration,
      };

    case INVITATION_SERVICE_VALUE:
      return {
        ...convertDefaultField,
        country: service.country,
        customer_nationality_region: service.nationalityRegion,
        visa_type: service.visaType,
        visa_duration: service.visaDuration,
      };
  }
};

export const convertOrderTypeToApi = orderType => ({
  translations: { en: orderType.translationEn, de: orderType.translationDe },
  is_active: orderType.isActive,
  is_individual: orderType.enableForIndividual,
  is_legal: orderType.enableForLegal,
  country_is_required: orderType.enableCountry,
  available_for_countries: orderType.availableForCountries,
  applicant_max_quantity: orderType.applicantMaxQuantity,
  applicant_birthdate_is_required: orderType.enableApplicantBirthdate,
  applicant_passport_is_required: orderType.enableApplicantPassport,
  contact_person_is_required: orderType.enableContactPerson,
  residency_is_required: orderType.enableResidency,
  description_is_required: orderType.enableDescription,
  visa_type_is_required: orderType.enableVisaType,
  visa_urgency_is_required: orderType.enableVisaUrgency,
  documents_received_by_is_required: orderType.enableDocumentsReceivedBy,
  delivery_method_is_required: orderType.enableDeliveryMethod,
  field_documents_to_consulate_estimated_at:
    orderType.enableDocumentsToConsEstimatedAt,
  field_documents_to_consulate_actual_at:
    orderType.enableDocumentsToConsActualAt,
  field_documents_from_consulate_estimated_at:
    orderType.enableDocumentsFromConsEstimatedAt,
  field_documents_from_consulate_actual_at:
    orderType.enableDocumentsFromConsActualAt,
  field_documents_to_applicant_estimated_at:
    orderType.enableDocumentsToApplicantEstimatedAt,
  field_documents_to_applicant_actual_at:
    orderType.enableDocumentsToApplicantActualAt,
  first_date_of_entry: orderType.enableFirstDateOfEntry,
  gultig_from: orderType.enableGultigFrom,
  gultig_to: orderType.enableGultigTo,
  // Used for Update request
  // attached_conditions: orderType.attachedConditions?.map(cond => ({
  //   ...cond,
  //   if_product: cond.if_product?.value ?? null,
  //   if_not_product: cond.if_not_product?.value ?? null,
  //   visa_duration: cond.visa_duration?.value ?? null,
  // })),
});

export const convertOrderTypeDocumentsToApi = data => ({
  country: data.country,
  documents: data.documents?.map(doc => ({
    id: doc.id,
    position: doc.position,
    conditions: doc.conditions.map(cond => ({
      ...cond,
      if_product: cond.if_product?.value ?? null,
      if_not_product: cond.if_not_product?.value ?? null,
      visa_duration: cond.visa_duration?.value ?? null,
    })),
  })),
  id: data.id,
  visa_type: data.visa,
});

export const convertOrderTypeFromApi = orderType => ({
  id: orderType.id,
  translationEn: orderType.translations?.en,
  translationDe: orderType.translations?.de,
  isActive: orderType.is_active,
  enableForIndividual: orderType.is_individual,
  enableForLegal: orderType.is_legal,
  enableCountry: orderType.country_is_required,
  availableForCountries: orderType.available_for_countries?.map(
    country => country.id,
  ),
  applicantMaxQuantity: orderType.applicant_max_quantity,
  enableApplicantBirthdate: orderType.applicant_birthdate_is_required,
  enableApplicantPassport: orderType.applicant_passport_is_required,
  enableContactPerson: orderType.contact_person_is_required,
  enableResidency: orderType.residency_is_required,
  enableDescription: orderType.description_is_required,
  enableVisaType: orderType.visa_type_is_required,
  enableVisaUrgency: orderType.visa_urgency_is_required,
  enableDocumentsReceivedBy: orderType.documents_received_by_is_required,
  enableDeliveryMethod: orderType.delivery_method_is_required,
  enableDocumentsToConsEstimatedAt:
    orderType.field_documents_to_consulate_estimated_at,
  enableDocumentsToConsActualAt:
    orderType.field_documents_to_consulate_actual_at,
  enableDocumentsFromConsEstimatedAt:
    orderType.field_documents_from_consulate_estimated_at,
  enableDocumentsFromConsActualAt:
    orderType.field_documents_from_consulate_actual_at,
  enableDocumentsToApplicantEstimatedAt:
    orderType.field_documents_to_applicant_estimated_at,
  enableDocumentsToApplicantActualAt:
    orderType.field_documents_to_applicant_actual_at,
  enableFirstDateOfEntry: orderType.first_date_of_entry,
  enableGultigFrom: orderType.gultig_from,
  enableGultigTo: orderType.gultig_to,
  //
  // documents: orderType.documents,  todo: I think we do not need it anymore
  countries: orderType.countries,
});

export const convertServicesFromApi = service => ({
  name: service.name,
  serviceType: service.product_type.type,
  price: service.product_net_price_euro,
  title: service.title,
  description: service.description,
  country: service.country ?? '',
  nationality: service.customer_nationality ?? '',
  nationalityRegion: service.customer_nationality_region ?? '',
  exceptNationalities: service.except_nationalities,
  status: service.status?.status,
  visaType: service.visa_type,
  visaUrgency: service.visa_urgency,
  visaDuration: service.visa_duration,
  taxEnabled: service.tax_enabled,
  isAdministrative: service.is_administrative,
  processing: service.processing,
  orderType: service.order_type,
  // autoSelect: service.auto_select,
  tariffs: service.tariffs.map((tariff, index) => ({
    ...tariff,
    price: formatCurrency(tariff.price),
    autoSelect: tariff.auto_select,
    tariff: {
      id: service.tariffs[index].tariff?.id,
      label: service.tariffs[index].tariff?.name,
      value: service.tariffs[index].tariff?.id,
    },
  })),
});

export const convertApplicantFromApi = applicant => ({
  userName: applicant.user?.username ?? null,
  firstName: applicant.first_name,
  lastName: applicant.last_name,
  birthday: new Date(applicant.birthday),
  id: applicant.id,
  additionalName: applicant.additional_name,
  passport: applicant.passport && convertPassportFromApi(applicant.passport),
  passports:
    applicant?.passports.length === 0
      ? [{ country: '', issue_date: null, end_date: null }]
      : applicant.passports.map(passport => ({
          ...passport,
          country: passport.country.id,
          visa_order_require_residency:
            passport.country.visa_order_require_residency,
          countryDetail: { shortLabel: passport.country.country.name },
          end_date: new Date(passport.end_date),
          issue_date: new Date(passport.issue_date),
        })),
  documents: applicant.documents,
  description: applicant.description,
  sex: applicant.sex,
  phone: applicant.phone,
  email: applicant.email,
});

export const converPassportToApi = passport => ({
  id: passport?.id ?? null,
  number: passport?.number ?? null,
  country: passport?.nationality ?? null,
  end_date: convertDateToAPI(passport?.expirationDate) ?? null,
  issue_date: convertDateToAPI(passport?.startDate) ?? null,
});

export const convertApplicantBasicDataToApi = values => ({
  first_name: values.firstName,
  last_name: values.lastName,
  additional_name: values.additionalName,
  sex: values.sex,
  phone: values.phone,
  email: values.email,
});

export const convertApplicantWithBirthdateToApi = values => ({
  birthday: convertDateToAPI(values.birthday),
  ...convertApplicantBasicDataToApi(values),
});

export const convertApplicantToApi = values => ({
  ...convertApplicantWithBirthdateToApi(values),
  passports:
    values.passports?.length > 0
      ? values.passports.map(passport => {
          if (passport.id === values.passport.id) {
            return converPassportToApi(values.passport);
          }

          return {
            ...passport,
            end_date: convertDateToAPI(passport.end_date) ?? null,
            issue_date: convertDateToAPI(passport.issue_date) ?? null,
            country: passport?.country.id ?? passport?.country ?? null,
          };
        })
      : [converPassportToApi(values.passport)],
  // first_name: values.firstName,
  // last_name: values.lastName,
  // birthday: convertDateToAPI(values.birthday),
  // additional_name: values.additionalName,
  // sex: values.sex,
  // phone: values.phone,
  // email: values.email,
});

export const convertApplicantManagerToApi = values => ({
  first_name: values.firstName,
  last_name: values.lastName,
  birthday: convertDateToAPI(values.birthday),
  additional_name: values.additionalName,
  passports: values.passports.map(passport => ({
    ...passport,
    end_date: convertDateToAPI(passport.end_date),
    issue_date: convertDateToAPI(passport.issue_date),
  })),
  sex: values.sex,
  phone: values.phone,
  email: values.email,
});

const prepareDocuments = customerDocuments => {
  if (customerDocuments.length === 0 || !customerDocuments[0]) {
    return [];
  }

  const result = customerDocuments?.map(item => {
    if (item?.documentOrderId && item.file) {
      return {
        id: item?.documentOrderId,
        document: item.file.applicant_document_id,
        comment: item.file.comment,
      };
    }

    if (item.file) {
      return {
        document: item.file.id,
        comment: item.file.comment,
      };
    }
  });

  return result?.length > 0 ? result : [];
};

export const convertDeliveryToApi = delivery => ({
  id: delivery?.id ?? undefined,
  carrier: delivery?.provider?.carrier ?? delivery?.provider,
  // carrier: delivery?.provider?.carrier,
  order: delivery.order,
  method: delivery?.methodWithId,
  comment: delivery.comment,
  track_number: delivery?.number ?? undefined,
  // client_expense: delivery.immediately,
  issue_now: delivery?.issue_now | false,

  from_company: delivery?.from?.companyName,
  from_name: delivery?.from?.name,
  from_address: delivery?.from?.address,
  from_city: delivery?.from?.city,
  from_state: delivery?.from?.state,
  from_postal_code: delivery?.from?.code,
  from_country: delivery?.from?.country,
  from_phone: delivery?.from?.phone,
  from_email: delivery?.from?.email,

  to_company: delivery?.to?.companyName,
  to_name: delivery?.to?.name,
  to_address: delivery?.to?.address,
  to_city: delivery?.to?.city,
  to_state: delivery?.to?.state,
  to_postal_code: delivery?.to?.code,
  to_country: delivery?.to?.country,
  to_phone: delivery?.to?.phone,
  to_email: delivery?.to?.email,
});

export const convertDeliveryFromApi = (deliveries = []) =>
  deliveries.map(delivery => ({
    id: delivery?.id,
    comment: delivery?.comment,
    provider: delivery?.carrier?.carrier,
    providerDetail: delivery?.carrier,
    method: delivery?.method?.method,
    methodDetail: delivery?.method,
    order: delivery.order,
    number: delivery?.track_number ?? undefined,
    editable: delivery?.editable,
    // immediately: delivery.client_expense,
    issue_now: delivery?.issue_now,
    shipmentLabel: delivery?.shipment_label_link,
    shipmentCost: delivery?.shipment_cost,

    from: {
      companyName: delivery?.from_company,
      name: delivery?.from_name,
      address: delivery?.from_address,
      city: delivery?.from_city,
      state: delivery?.from_state,
      code: delivery?.from_postal_code,
      country: delivery?.from_country,
      countryName: delivery?.from_country_name,
      phone: delivery?.from_phone,
      email: delivery?.from_email,
    },

    to: {
      companyName: delivery?.to_company,
      name: delivery?.to_name,
      address: delivery?.to_address,
      city: delivery?.to_city,
      state: delivery?.to_state,
      code: delivery?.to_postal_code,
      country: delivery?.to_country,
      countryName: delivery?.to_country_name,
      phone: delivery?.to_phone,
      email: delivery?.to_email,
    },
  }));

const convertOrderApplicantNoPassport = applicant => ({
  id: applicant?.customerId,
  applicant: applicant.applicantDetail.id,
  customer_first_name: applicant.applicantDetail.firstName,
  customer_last_name: applicant.applicantDetail.lastName,
  phone: applicant.applicantDetail.phone,
  sex: applicant.applicantDetail.sex,
  documents: prepareDocuments(applicant.files),
  email: applicant.applicantDetail.email,
  // customer_nationality: applicant.applicantDetail.passport?.nationality ?? null,
  has_residency: applicant.hasResidency,
  other_countries: applicant?.visaRequired,
  previous_visa: applicant?.previusVisa,
  previous_ussr: applicant?.previousUssr,
  previous_expiration_date: convertDateToAPI(applicant?.prevExpirationDate),
  products: applicant.services.map(service => ({
    id: service.orderId,
    product: service.id,
    ...((service.product_price || service.product_price === 0) && {
      product_price: service.product_price,
    }),
  })),
  status: applicant?.statusApplicant,
  visa_type: applicant?.visaType,
  visa_duration: applicant?.visaDuration,
  visa_urgency: applicant.visaUrgency,
  comments: applicant.comment,
  description: applicant.description,

  // UPS Shipping Label
  ups_label: applicant.upsLabel,
  ups_label_name: applicant.upsName,
  ups_label_address1: applicant.upsAddressLineFirst,
  ups_label_postal_code: applicant.upsPostalCode,
  ups_label_city: applicant.upsCity,
  ups_label_state: applicant.upsState,
  ups_label_country: applicant.upsCountry,

  // Delivery Method
  delivery_method: applicant.deliveryMethod,
  delivery_mailbox_allowed: applicant?.address?.mailBox,
  delivery_with_applicant: applicant.addressAnotherApplicant,
  delivery_name: applicant?.address?.deliveryName,
  delivery_address1: applicant?.address?.addressLineFirst,
  delivery_postal_code: applicant?.address?.postalCode,
  delivery_city: applicant?.address?.city,
  delivery_state: applicant?.address?.state,
  delivery_country: applicant?.address?.country,
  gultig_from: convertDateToAPI(applicant?.gultigFrom),
  gultig_to: convertDateToAPI(applicant?.gultigTo),

  // VISUM.IO
  visum_integration_access_token: applicant?.visumIntegrationAccessToken,
  // Dates
  documents_to_consulate_estimated_at: convertDateToAPI(
    applicant.documentsToConsulateEstimatedAt,
  ),
  documents_to_consulate_actual_at: convertDateToAPI(
    applicant.documentsToConsulateActualAt,
  ),

  documents_from_consulate_estimated_at: convertDateToAPI(
    applicant.documentsFromConsulateEstimatedAt,
  ),
  documents_from_consulate_actual_at: convertDateToAPI(
    applicant.documentsFromConsulateActualAt,
  ),

  documents_to_applicant_estimated_at: convertDateToAPI(
    applicant.documentsToApplicantEstimatedAt,
  ),
  documents_to_applicant_actual_at: convertDateToAPI(
    applicant.documentsToApplicantActualAt,
  ),
  documents_received_by: applicant?.documentsReceivedBy,

  first_date_of_entry: convertDateToAPI(applicant.firstDateEntry),
});

const convertOrderApplicantWithPassport = applicant => ({
  id: applicant?.customerId,
  applicant: applicant.applicantDetail.id,
  customer_first_name: applicant.applicantDetail.firstName,
  customer_last_name: applicant.applicantDetail.lastName,

  passport_number: applicant.applicantDetail.passport?.number ?? null,
  passport_expire_date: convertDateToAPI(
    applicant.applicantDetail.passport?.expirationDate ?? null,
  ),
  passport_issue_date: convertDateToAPI(
    applicant.applicantDetail.passport?.startDate ?? null,
  ),

  phone: applicant.applicantDetail.phone,
  sex: applicant.applicantDetail.sex,
  documents: prepareDocuments(applicant.files),
  email: applicant.applicantDetail.email,
  customer_nationality:
    applicant.applicantDetail.passport?.nationality ||
    applicant.applicantDetail.citizenship ||
    null,
  has_residency: applicant.hasResidency,
  other_countries: applicant?.visaRequired,
  previous_visa: applicant?.previusVisa,
  previous_ussr: applicant?.previousUssr,
  previous_expiration_date: convertDateToAPI(applicant?.prevExpirationDate),
  products: applicant.services.map(service => ({
    id: service.orderId,
    product: service.id,
    ...((service.product_price || service.product_price === 0) && {
      product_price: service.product_price,
    }),
  })),
  status: applicant?.statusApplicant,
  visa_type: applicant?.visaType,
  visa_duration: applicant?.visaDuration,
  visa_urgency: applicant.visaUrgency,
  comments: applicant.comment,
  description: applicant.description,

  // UPS Shipping Label
  ups_label: applicant.upsLabel,
  ups_label_name: applicant.upsName,
  ups_label_address1: applicant.upsAddressLineFirst,
  ups_label_postal_code: applicant.upsPostalCode,
  ups_label_city: applicant.upsCity,
  ups_label_state: applicant.upsState,
  ups_label_country: applicant.upsCountry,

  // Delivery Method
  delivery_method: applicant.deliveryMethod,
  delivery_mailbox_allowed: applicant?.address?.mailBox,
  delivery_with_applicant: applicant.addressAnotherApplicant,
  delivery_name: applicant?.address?.deliveryName,
  delivery_address1: applicant?.address?.addressLineFirst,
  delivery_postal_code: applicant?.address?.postalCode,
  delivery_city: applicant?.address?.city,
  delivery_state: applicant?.address?.state,
  delivery_country: applicant?.address?.country,
  gultig_from: convertDateToAPI(applicant?.gultigFrom),
  gultig_to: convertDateToAPI(applicant?.gultigTo),

  // VISUM.IO
  visum_integration_access_token: applicant?.visumIntegrationAccessToken,
  // Dates
  documents_to_consulate_estimated_at: convertDateToAPI(
    applicant.documentsToConsulateEstimatedAt,
  ),
  documents_to_consulate_actual_at: convertDateToAPI(
    applicant.documentsToConsulateActualAt,
  ),

  documents_from_consulate_estimated_at: convertDateToAPI(
    applicant.documentsFromConsulateEstimatedAt,
  ),
  documents_from_consulate_actual_at: convertDateToAPI(
    applicant.documentsFromConsulateActualAt,
  ),

  documents_to_applicant_estimated_at: convertDateToAPI(
    applicant.documentsToApplicantEstimatedAt,
  ),
  documents_to_applicant_actual_at: convertDateToAPI(
    applicant.documentsToApplicantActualAt,
  ),
  documents_received_by: applicant?.documentsReceivedBy,

  first_date_of_entry: convertDateToAPI(applicant.firstDateEntry),
});

export const converOrderToApi = (values, enableApplicantPassport) => ({
  order_type: values.orderType,
  client:
    values.client.clientType === 'individual' ? values.client.client : null,
  organization:
    values.client.clientType === 'legal' ? values.client.client : null,
  internal_number: values.client.internalNumber ?? null,
  accounting_unit: values.client.accountingUnit ?? null,
  consulate: values.client.consulate,
  country: values.client.country,
  customer: isUserEmployee
    ? enableApplicantPassport
      ? values.applicants.map(convertOrderApplicantWithPassport).at()
      : values.applicants.map(convertOrderApplicantNoPassport).at()
    : undefined,
  customers: isUserEmployee
    ? undefined
    : enableApplicantPassport
    ? values.applicants.map(convertOrderApplicantWithPassport)
    : values.applicants.map(convertOrderApplicantNoPassport),
});

const convertOrderCustomer = customer => ({
  customerId: customer.id,
  comment: customer.comments,
  hasResidency: customer.has_residency,
  applicantDetail: {
    firstName: customer.applicant.first_name,
    lastName: customer.applicant.last_name,
    additionalName: customer.applicant.additional_name,
    email: customer.applicant.email,
    id: customer.applicant.id,
    citizenship: customer.nationality?.id,
    visaOrderRequireResidency:
      customer.nationality?.visa_order_require_residency,
    //need for free date formatting
    birthday: new Date(customer.applicant.birthday),
    passport: {
      startDate: new Date(customer.passport_issue_date),
      expirationDate: new Date(customer.passport_expire_date),
      number: customer.passport_number,
      nationality: customer.customer_nationality,
    },
    phone: customer.phone,
    sex: customer.sex,
  },
  description: customer.description,

  // UPS Shipping label
  upsLabel: customer.ups_label,
  upsName: customer.ups_label_name,
  upsAddressLineFirst: customer.ups_label_address1,
  upsPostalCode: customer.ups_label_postal_code,
  upsCity: customer.ups_label_city,
  upsState: customer.ups_label_state,
  upsCountry: customer.ups_label_country?.id,

  // Delivery Method
  deliveryMethod: customer.delivery_method?.value,
  addressAnotherApplicant: customer.delivery_with_applicant,
  address: {
    deliveryMethod: customer.delivery_method?.value,
    deliveryName: customer.delivery_name,
    addressLineFirst: customer.delivery_address1,
    city: customer.delivery_city,
    country: customer.delivery_country?.id,
    state: customer.delivery_state,
    postalCode: customer.delivery_postal_code,
    mailBox: customer.delivery_mailbox_allowed?.value,
  },
  services: customer.products.map(product => ({
    name: product.name,
    title: product.title,
    processing: product.processing,
    tax: product.tax,
    product_type: product.product_type,
    product_tax_percent_human: product.product_tax_percent_human,
    product_tax_total_euro: product.product_tax_total_euro,
    product_tax_total_cents: product.product_tax_total_cents,
    product_total_price_cents: product.product_total_price_cents,
    product_net_price_cents: product.product_net_price_cents,
    id: product?.product?.id,
    is_visa_type: product.is_visa_type,
    is_invoice_locked: product.is_invoice_locked,
    isAdministrative: product.is_administrative,
    orderId: product.id,
    product_id: product.product_id,
  })),
  files: customer.documents.map(document => ({
    file: document,
    documentOrderId: document.id,
  })),
  prevExpirationDate: customer.previous_expiration_date
    ? new Date(customer.previous_expiration_date)
    : null,
  visaType: customer.visa_type?.id ?? undefined,
  visaDuration: customer.visa_duration?.id ?? undefined,
  visaUrgency: customer.visa_urgency?.id ?? undefined,
  statusApplicant:
    typeof customer.status === 'object'
      ? customer.status.value
      : customer.status,
  previusVisa: customer.previous_visa.value ?? [PREVIOUS_VISA_STATE[0].value],
  previousUssr: customer.previous_ussr,
  visaRequired: customer.other_countries,
  gultigFrom: customer.gultig_from ? new Date(customer.gultig_from) : null,
  gultigTo: customer.gultig_to ? new Date(customer.gultig_to) : null,
  visumIntegrationAccessToken: customer?.visum_integration_access_token,
  visumIntegrationFillingStatus: customer?.visum_integration_filling_status,
  visumIntegrationVisaStatus: customer?.visum_integration_visa_status,
  documentsReceivedBy: customer.documents_received_by,

  // Documents to consulate
  documentsToConsulateEstimatedAt: customer.documents_to_consulate_estimated_at
    ? new Date(customer.documents_to_consulate_estimated_at)
    : null,
  documentsToConsulateActualAt: customer.documents_to_consulate_actual_at
    ? new Date(customer.documents_to_consulate_actual_at)
    : null,

  // Documents from consulate
  documentsFromConsulateEstimatedAt:
    customer.documents_from_consulate_estimated_at
      ? new Date(customer.documents_from_consulate_estimated_at)
      : null,
  documentsFromConsulateActualAt: customer.documents_from_consulate_actual_at
    ? new Date(customer.documents_from_consulate_actual_at)
    : null,

  // Documents to applicant
  documentsToApplicantEstimatedAt: customer.documents_to_applicant_estimated_at
    ? new Date(customer.documents_to_applicant_estimated_at)
    : null,
  documentsToApplicantActualAt: customer.documents_to_applicant_actual_at
    ? new Date(customer.documents_to_applicant_actual_at)
    : null,

  firstDateEntry: customer.first_date_of_entry
    ? new Date(customer.first_date_of_entry)
    : null,
});

export const converFromApi = values => {
  return {
    orderDetail: {
      isEditable: values.is_editable ?? true,
      orderStatus: values.order_status,
      totalPrice: values.total_price,
      dateCreate: values.created_at,
      createdBy: values.created_by,
      number: values.number,
      assignedTo: values.assignedTo,
      // orderType: values.order_type,
    },

    orderType: values.order_type,
    invoices: values.bills,
    client: {
      client: values.client
        ? values.client?.id
        : values?.customer_organization?.id,
      clientType: isUserOperator
        ? values.client
          ? 'individual'
          : 'legal'
        : undefined,
      country: values.country?.id,
      internalNumber: values.internal_number ?? null,
      accountingUnit: values.accounting_unit ?? null,
      consulate: values?.consulate?.id,
    },
    applicants: values.customers
      ? values.customers.map(convertOrderCustomer)
      : [convertOrderCustomer(values.customer)],

    contactPerson: {
      status: values.contact_person?.status ?? null,
      firstName: values.contact_person?.first_name ?? null,
      lastName: values.contact_person?.last_name ?? null,
      additionalName: values.contact_person?.additional_name ?? null,
      phone: values.contact_person?.phone ?? null,
      email: values.contact_person?.email ?? null,
      // urgency: null,
      comments: null,
      // documentsReceivedBy: null,

      // Delivery

      // delivery_method: null,
      // deliveryMailboxAllowed: false,
      // deliveryName: null,
      // deliveryAddress1: null,
      // deliveryPostalCode: null,
      // deliveryCity: null,
      // deliveryState: null,
      // deliveryCountry: null,
      // delivery_carrier: null,

      // // UPS

      // upsLabel: null,
      // upsLabelName: null,
      // upsLabelAddress1: null,
      // upsLabelPostalCode: null,
      // upsLabelCity: null,
      // upsLabelState: null,
      // upsLabelCountry: null,
      // upsLabelDocument: null,
      // upsLabelMimeType: null,

      // // Dates
      // documentsToConsulateEstimatedAt: null,
      // documentsToConsulateActualAt: null,
      // documentsFromConsulateEstimatedAt: null,
      // documentsFromConsulateActualAt: null,
      // documentsToPersonEstimatedAt: null,
      // documentsToPersonActualAt: null
    },
    deliveries: convertDeliveryFromApi(values.shipments),
  };
};

const convertClientAddress = address => {
  const addressConverted = {
    id: address?.id,
    name: address?.name,
    city: address?.city,
    addressLineFirst: address?.address1,
    postalCode: address?.postal_code ?? '',
    state: address?.state,
    country: address?.country?.id,
  };

  return Object.values(addressConverted).filter(field => field).length === 0
    ? undefined
    : addressConverted;
};

export const convertAddressesFromApi = addresses =>
  addresses.map(convertClientAddress);

export const convertIndividualFromApi = client => ({
  clientType: 'individual',
  id: client?.id,
  address: convertClientAddress(client.address),

  mainInfo: {
    clientType: 'individual',
    taxId: client?.tax_id ?? null,
    clientId: client?.client_id ?? null,
    taxResidencyCountry: client?.tax_residency_country?.id ?? null,
    firstName: client?.first_name,
    lastName: client?.last_name,
    additionalName: client?.additional_name,
    status: client?.status,
    tariff: client?.tariff?.id,
    tariffDetail: client?.tariff,
    login: client?.username,
    lastLogin: client?.last_login,
    comment: client?.comment,

    description: client?.description ?? null,
    account_collection_auth: client?.account_collection_auth ?? null,
    account_owner: client?.account_owner ?? null,
    account_bank: client?.account_bank ?? null,
    account_due: client?.account_due ?? 0,
    iban: client?.iban ?? null,
  },

  contacts: {
    phone: client?.phone ?? '',
    email: client?.email ?? '',
  },
});

export const convertClientAddressToAPI = address => {
  if (!address || Object.values(address).length === 0) {
    return undefined;
  }

  const addressCandidate = {
    id: address?.id,
    name: optionalField(address?.name),
    city: optionalField(address?.city),
    address1: optionalField(address?.addressLineFirst),
    postal_code: optionalField(address?.postalCode),
    state: optionalField(address?.state) ?? '',
    country: optionalField(address?.country),
  };

  return Object.values(addressCandidate).filter(field => field).length === 0
    ? undefined
    : addressCandidate;
};

export const convertClientToAPI = client => ({
  id: client.id,
  client_type: client.clientType,
  status: client.id ? client.mainInfo.status : undefined,
  tariff: client.mainInfo.tariff,
  tax_id: client.mainInfo.taxId ?? null,
  client_id: client.mainInfo.clientId ?? null,
  tax_residency_country: client.mainInfo.taxResidencyCountry,
  comment: client.mainInfo.comment,
  phone: optionalField(client.contacts.phone),
  fax: optionalField(client.contacts.fax),
  email: optionalField(client.contacts.email),

  description: client.mainInfo?.description ?? null,
  account_collection_auth: client.mainInfo?.account_collection_auth ?? false,
  account_owner: client.mainInfo?.account_owner ?? null,
  account_bank: client.mainInfo?.account_bank ?? null,
  account_due: client.mainInfo?.account_due ?? 0,
  iban: client.mainInfo?.iban ?? null,

  category: client.clientType === 'legal' ? client.mainInfo.category : null,

  notify_organization:
    client.clientType === 'legal'
      ? client.clientOptions.notify_organization
      : undefined,

  notify_all_managers:
    client.clientType === 'legal'
      ? client.clientOptions.notify_all_managers
      : undefined,

  notify_created_by_manager:
    client.clientType === 'legal'
      ? client.clientOptions.notify_created_by_manager
      : undefined,

  notify_applicant:
    client.clientType === 'legal'
      ? client.clientOptions.notify_applicant
      : undefined,

  manager_can_view_price:
    client.clientType === 'legal'
      ? client.clientOptions.managerViewPrice
      : undefined,

  employee_can_view_price:
    client.clientType === 'legal'
      ? client.clientOptions.employeeViewPrice
      : undefined,

  employee_can_create_order:
    client.clientType === 'legal'
      ? client.clientOptions.employeeCreateOrder
      : undefined,

  name:
    client.clientType === 'legal'
      ? optionalField(client.mainInfo.organizationName)
      : undefined,
  contact_name:
    client.clientType === 'legal'
      ? optionalField(client.contacts.contactName)
      : undefined,
  contact_job_title:
    client.clientType === 'legal'
      ? optionalField(client.contacts.contactJobTitle)
      : undefined,

  first_name:
    client.clientType === 'individual'
      ? optionalField(client.mainInfo.firstName)
      : undefined,

  last_name:
    client.clientType === 'individual'
      ? optionalField(client.mainInfo.lastName)
      : undefined,

  address: convertClientAddressToAPI(client?.address),
});

export const convertOrganizationFromApi = client => ({
  clientType: 'legal',
  id: client.id,
  address: convertClientAddress(client?.address),

  mainInfo: {
    clientType: 'legal',
    taxId: client?.tax_id,
    clientId: client?.client_id,
    taxResidencyCountry: client?.tax_residency_country?.id ?? null,
    organizationName: client?.name,
    category: client?.category,
    status: client?.status,
    tariff: client?.tariff?.id,
    tariffDetail: client?.tariff,
    comment: client?.comment ?? null,
    description: client?.description ?? null,
    account_collection_auth: client?.account_collection_auth ?? null,
    account_owner: client?.account_owner ?? null,
    account_bank: client?.account_bank ?? null,
    account_due: client?.account_due ?? 0,
    iban: client?.iban ?? null,
  },

  clientOptions: {
    employee_can_create_order: client?.employee_can_create_order,

    notify_organization: client?.notify_organization,
    notify_all_managers: client?.notify_all_managers,
    notify_created_by_manager: client?.notify_created_by_manager,
    notify_applicant: client.notify_applicant,

    managerViewPrice: client?.manager_can_view_price,
    employeeViewPrice: client?.employee_can_view_price,
    employeeCreateOrder: client?.employee_can_create_order,
  },

  contacts: {
    phone: client?.phone ?? '',
    fax: client?.fax ?? '',
    email: client?.email ?? '',
    contactName: client?.contact_name,
    contactJobTitle: client?.contact_job_title,
  },
});

export const convertUserUpdateToAPI = user => ({
  phone: user.phone,
  last_name: user.last_name,
  first_name: user.first_name,
  email: user.email,
  role: user?.role,
  organization:
    user.role === 'manager' || user.role === 'employee'
      ? optionalField(user.organization.id)
      : undefined,
  password: optionalField(user.password),
  status: !user.id && 'new',
  username: (user.username?.length >= 3 && user.username) || null,
});

export const convertUserFromAPI = currentUser => ({
  ...currentUser,
  organization: currentUser?.organization,
  phone: currentUser?.phone ?? '',
  role: currentUser?.role,
  password: '',
  verifyPassword: '',
});

export const convertInvoiceFromAPI = invoice => ({
  client: invoice.client,
  periodStart: new Date(invoice.period_from),
  status: invoice.status,
  periodEnd: new Date(invoice.period_to),
  invoices: invoice.bills,
  created: new Date(invoice.created_at),
  waitPaymentAt: invoice.wait_payment_at
    ? new Date(invoice.wait_payment_at)
    : null,
  number: invoice.number,
});

export const convertInvoiceToAPI = values => ({
  client_type: values.client?.client_type?.client_type,
  client_id: values.client?.id,
  period_from: convertDateToAPI(values.periodStart),
  period_to: convertDateToAPI(values.periodEnd),
  bills: values.invoices?.map(invoice => invoice.id),
  countries: values.countries?.map(country => country.value),
});
